import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import KitInformation from './components/KitInformation';
import ContentsInformation from './components/ContentsInformation';
import ShowContainer from '../../shared/components/show/showContainer';

const KitsShow = () => (
  <Container>
    <ShowContainer>
      <KitInformation />
      <hr />
      <ContentsInformation />
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(KitsShow));

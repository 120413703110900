import React from 'react';
import { Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const BorderedTable = ({ heads, rows, pendingRequest }) => (
  <Table className="table--bordered" responsive>
    <thead>
      <tr>
        {
          heads.map((header) => {
            const { key, name } = header;
            return <th key={key}>{name}</th>;
          })
        }
      </tr>
    </thead>
    <tbody>
      {
        (pendingRequest || rows.length > 0)
          ? rows
          : <tr className="not-found-text"><td>No results found with this filter</td></tr>
      }
    </tbody>
  </Table>
);

BorderedTable.propTypes = {
  heads: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    editable: PropTypes.bool,
    sortable: PropTypes.bool,
  })).isRequired,
  pendingRequest: PropTypes.bool.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default withTranslation('common')(BorderedTable);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getSearchMeta } from '../../../redux/reducers/indexReducer';

import PaginationInfoDisplay from './PaginationInfoDisplay';

const PaginationInfoContainer = (props) => {
  const { searchMeta } = props;
  return searchMeta.totalResults > 0 ? <PaginationInfoDisplay {...props} /> : null;
};

PaginationInfoContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  searchMeta: PropTypes.shape({
    limit: PropTypes.number,
    totalResults: PropTypes.number,
    offset: PropTypes.number,
  }),
};

PaginationInfoContainer.defaultProps = {
  searchMeta: {},
};

const mapStateToProps = (state, props) => {
  const { history } = props;
  return {
    searchMeta: getSearchMeta(history, state),
  };
};

export default withRouter(connect(mapStateToProps)(PaginationInfoContainer));

import { API_START, API_END } from '../actions/apiActions';

const initialState = {
  pending: false,
};

export default function devicesReducer(state = initialState, action) {
  switch (action.type) {
    case API_START:
      return {
        ...state,
        pending: true,
      };
    case API_END:
      return {
        ...state,
        pending: false,
      };
    default:
      return state;
  }
}

export const getApiPending = state => state.api.pending;

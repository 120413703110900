export default function setResourceData(history, params = null) {
  const { location } = history;
  const { pathname } = location;
  const resourcePath = pathname.split('/')[2];
  const preResourcePath = pathname.split('/')[1];
  const id = params && params.id;
  let resourceData = {};
  switch (resourcePath) {
    case 'items':
      resourceData = {
        resource: 'items',
        resourceExtension: 'items',
        preResourcePath,
        id,
      };
      break;
    case 'logs':
      resourceData = {
        resource: 'inventoryLogs',
        resourceExtension: 'inventory_logs',
        preResourcePath,
        id,
      };
      break;
    case 'devices':
      resourceData = {
        resource: 'devices',
        resourceExtension: 'devices',
        preResourcePath,
        id,
      };
      break;
    case 'bundles':
      resourceData = {
        resource: 'buildLists',
        resourceExtension: 'build_lists',
        preResourcePath,
        id,
      };
      break;
    case 'batches':
      resourceData = {
        resource: 'buildOrders',
        resourceExtension: 'build_orders',
        preResourcePath,
        id,
      };
      break;
    case 'kits':
      resourceData = {
        resource: 'kits',
        resourceExtension: 'kits',
        preResourcePath,
        id,
      };
      break;
    case 'receiving-orders':
      resourceData = {
        resource: 'receivingOrders',
        resourceExtension: 'vendor_orders',
        preResourcePath,
        id,
      };
      break;
    case 'receiving-shipments':
      resourceData = {
        resource: 'receivingShipments',
        resourceExtension: 'vendor_shipments',
        preResourcePath,
        id,
      };
      break;
    case 'sales-orders':
      resourceData = {
        resource: 'salesOrders',
        resourceExtension: 'orders',
        preResourcePath,
        id,
      };
      break;
    case 'sales-shipments':
      resourceData = {
        resource: 'salesShipments',
        resourceExtension: 'shipments',
        preResourcePath,
        id,
      };
      break;
    case 'return-orders':
      resourceData = {
        resource: 'returnOrders',
        resourceExtension: 'orders',
        preResourcePath,
        id,
      };
      break;
    case 'return-shipments':
      resourceData = {
        resource: 'returnShipments',
        resourceExtension: 'shipments',
        preResourcePath,
        id,
      };
      break;
    // no default
  }
  return resourceData;
}

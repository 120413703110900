import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Formik, Form } from 'formik';
import {
  Container,
  Col,
  Row,
  Button,
} from 'reactstrap';

import DatePicker from 'react-datepicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import { getFilterables } from '../../redux/reducers/authReducer';
import Alert from '../../shared/components/general/Alert';
import SuccessModal from '../../shared/components/general/SuccessModal';
import TextField from '../../shared/components/form/TextField';
import SelectField from '../../shared/components/form/SelectField';

import { apiAction } from '../../redux/actions/apiActions';
import setResourceData from '../../helpers/setResourceData';
import { toCamelCase, humanizeCamelCase } from '../../helpers/convertKeys';

class FormIndex extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    // eslint-disable-next-line
    match: PropTypes.object,
    // eslint-disable-next-line
    location: PropTypes.object,
  }

  static defaultProps = {
    items: [],
  }

  constructor() {
    super();
    this.state = {
      serverErrors: {},
      createSuccess: false,
      vendorOrder: {},
    };
  }

  componentDidMount() {
    const { location, dispatch, history: domHistory } = this.props;
    const vendorOrderId = new URLSearchParams(location.search).get('vendor-order-id');

    dispatch(
      apiAction(
        {
          method: 'GET',
          url: `/vendor_orders/${vendorOrderId}`,
          onSuccess: (res) => {
            this.setState({ vendorOrder: res });
            return { type: 'FORM_REQUEST' };
          },
          onFailure: (errors) => {
            this.setState({ serverErrors: errors });
            return { type: 'FORM_REQUEST' };
          },
          domHistory,
        },
      ),
    );
  }

  handleClearServerErrors = () => {
    this.setState({ serverErrors: {} });
  }

  render() {
    const initialValues = {
      expectedQuantity: '',
      trackingNumber: '',
      itemId: null,
      eta: null,
    };

    // eslint-disable-next-line
    const { history: domHistory, items, dispatch, location } = this.props;
    const { serverErrors, createSuccess, vendorOrder } = this.state;
    const vendorOrderId = new URLSearchParams(location.search).get('vendor-order-id');
    const resourceData = setResourceData(domHistory);
    const { resourceExtension } = resourceData;
    const lineItems = vendorOrder.lineItems || [];
    const itemsList = items.map(i => ({ label: i.name, value: i.id }));
    const vendorOrderItemIds = lineItems.map(li => li.itemId);
    const filteredItemsList = itemsList.filter(itm => vendorOrderItemIds.includes(itm.value));

    return (
      <Container>
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const errors = {};
            if (!values.expectedQuantity) errors.expectedQuantity = 'Minimum 1 Required';
            if (!values.itemId) errors.itemId = 'Required';
            return errors;
          }}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            setTimeout(() => {
              dispatch(
                apiAction(
                  {
                    method: 'POST',
                    params: { ...values, vendorOrderId },
                    url: `/${resourceExtension}`,
                    onSuccess: () => {
                      this.setState({ createSuccess: true });
                      setTimeout(() => { domHistory.goBack(); }, 1500);
                      return { type: 'FORM_REQUEST' };
                    },
                    onFailure: (errors) => {
                      const fieldErrors = {};
                      errors.forEach((e) => { fieldErrors[toCamelCase(e.field)] = e.message; });
                      this.setState({ serverErrors: fieldErrors });
                      setErrors(fieldErrors);
                      return { type: 'FORM_REQUEST' };
                    },
                    domHistory,
                  },
                ),
              );
              setSubmitting(false);
            }, 400);
          }}
        >
          {({
            isSubmitting, values, touched, errors, setFieldValue, setFieldTouched,
          }) => (
            <Form className="form">
              <SuccessModal
                isOpen={createSuccess}
                title="Receiving Order Successfully Created!"
                colored
                btn="Success"
                message={`You will be redirected back to the ${resourceExtension} index page.`}
              />
              <h3 className="page-title">
                New Receiving Shipment Form
              </h3>
              <div className="customizer__form-group-wrap no-bottom-lin col-md-12" style={{ width: '100%' }}>
                <div className="col-md-12">
                  <h4>
                    Receiving Order #{vendorOrderId}
                  </h4>
                  <h3 className="page-subhead subhead">
                    Use this form to create a new vendor shipment.
                    A vendor shipment consists of a single item and a single tracking number.
                  </h3>
                </div>
                <div className="mx-3">
                  <Row style={{ width: '100%' }}>
                    <Col lg={6}>
                      <TextField
                        label="Expected Quantity"
                        fieldName="expectedQuantity"
                        placeholder="Expected Quantity..."
                      />
                    </Col>
                    <Col lg={6}>
                      <SelectField
                        label="Stock Item"
                        value={values.itemId}
                        filterKey="itemId"
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        error={errors.itemId}
                        touched={touched.itemId}
                        placeholder="Select Incoming Item..."
                        options={filteredItemsList}
                      />
                    </Col>
                    <Col lg={6}>
                      <TextField
                        label="Tracking Number"
                        fieldName="trackingNumber"
                        placeholder="Tracking Number..."
                      />
                    </Col>
                    <Col lg={6}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">ETA</span>
                        <div className="form__form-group-field">
                          <div className="date-picker">
                            <DatePicker
                              isClearable
                              placeholderText="ETA"
                              className="form__form-group-datepicker"
                              selected={
                                (
                                  values.eta
                                  && new Date(values.eta.replace(/-/g, '/'))
                                )
                                || null
                              }
                              onChange={val => (
                                setFieldValue(
                                  'eta',
                                  (val && val.toISOString().substring(0, 10)),
                                )
                              )}
                              dropDownMode="select"
                            />
                          </div>
                          <div className="form__form-group-icon input-icon-right">
                            <CalendarBlankIcon />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>


              </div>
              <div className="customizer__form-group-wrap no-bottom-line col-md-12" style={{ width: '100%' }}>
                <div className="ml-3 mr-5">
                  <Alert
                    color="danger"
                    className="alert--bordered"
                    icon
                    visible={Object.keys(serverErrors).length > 0}
                    onDismiss={this.handleClearServerErrors}
                  >
                    <p>
                      <span className="bold-text mr-2">
                        Warning!
                      </span>
                      One or more errors are preventing this record from being saved!
                    </p>
                    {
                      Object.keys(serverErrors).map(errorKey => (
                        <p className="ml-2" key={`error-${errorKey}`}>
                          <b className="mr-1">{humanizeCamelCase(errorKey)}:</b>
                          { serverErrors[errorKey] }
                        </p>
                      ))
                    }
                  </Alert>
                  <Row>
                    <Col xs={6}>
                      <Button
                        block
                        color="secondary"
                        disabled={isSubmitting}
                        onClick={domHistory.goBack}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col xs={6}>
                      <Button
                        block
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Create New Receiving Shipment
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  items: getFilterables(state).items,
});


export default withRouter(connect(mapStateToProps)(FormIndex));

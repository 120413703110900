import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import ReceivingOrderInformation from './components/show/ReceivingOrderInformation';
import ReceivingOrderLineItems from './components/show/ReceivingOrderLineItems';
import ReceivingOrderReceivingShipments from './components/show/ReceivingOrderReceivingShipments';
import ShowContainer from '../../shared/components/show/showContainer';

const Show = () => (
  <Container>
    <ShowContainer>
      <ReceivingOrderInformation />
      <hr />
      <ReceivingOrderLineItems />
      <hr />
      <ReceivingOrderReceivingShipments />
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(Show));

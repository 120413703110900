import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import LogInformation from './components/LogInformation';
import LogLedger from './components/LogLedger';
import ShowContainer from '../../shared/components/show/showContainer';

const InventoryLogsShow = () => (
  <Container>
    <ShowContainer>
      <LogInformation />
      <hr />
      <LogLedger />
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(InventoryLogsShow));

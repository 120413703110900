import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { updatePageAndSearch as updateApiPageAndSearch } from '../../../redux/actions/indexActions';
import { getSearchMeta } from '../../../redux/reducers/indexReducer';

import PaginationDisplay from './PaginationDisplay';

const PaginationContainer = props => <PaginationDisplay {...props} />;

PaginationContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  searchMeta: PropTypes.shape({
    limit: PropTypes.number,
    totalResults: PropTypes.number,
    offset: PropTypes.number,
  }),
  updatePageAndSearch: PropTypes.func.isRequired,
};

PaginationContainer.defaultProps = {
  searchMeta: {},
};

const mapStateToProps = (state, ownProps) => {
  const { history } = ownProps;
  return {
    searchMeta: getSearchMeta(history, state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  updatePageAndSearch: updateApiPageAndSearch,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaginationContainer));

import React from 'react';
import PasswordResetForm from './components/PasswordResetForm';

const PasswordReset = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <span className="login__logo" />
          <h3 className="account__title">Password Reset</h3>
        </div>
        <PasswordResetForm />
      </div>
    </div>
  </div>
);

export default PasswordReset;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import CheckBox from './CheckBox';

class CheckBoxGroup extends React.Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ).isRequired,
    error: PropTypes.string,
    id: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    touched: PropTypes.arrayOf(PropTypes.bool),
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  static defaultProps = {
    error: '',
    onBlur: () => {},
    touched: [],
  };

  handleChange = (event) => {
    const target = event.currentTarget;
    const { value, id, onChange } = this.props;
    const valueArray = [...value] || [];

    if (target.checked) {
      valueArray.push(target.id);
    } else {
      valueArray.splice(valueArray.indexOf(target.id), 1);
    }

    onChange(id, valueArray);
  };

  handleBlur = () => {
    const { onBlur, id } = this.props;
    onBlur(id, true);
  };

  buildChildren = () => {
    const { options, id, value: filterValue } = this.props;
    return options.map((opt) => {
      const { label, value } = opt;
      return (
        <Field
          key={`${value}_checkbox'`}
          component={CheckBox}
          name={id}
          id={value}
          label={label}
          field={{
            value: filterValue.includes(value),
            onChange: this.handleChange,
            onBlur: this.handleBlur,
          }}
        />
      );
    });
  }

  render() {
    const { error, touched } = this.props;

    return (
      <fieldset>
        {this.buildChildren()}
        {touched && error}
      </fieldset>
    );
  }
}

export default CheckBoxGroup;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import Information from './components/show/Information';
import AddressInformation from './components/show/AddressInformation';
import LineItems from './components/show/LineItems';
import ShowContainer from '../../shared/components/show/showContainer';

const showInded = () => (
  <Container>
    <ShowContainer>
      <Information />
      <hr />
      <AddressInformation />
      <hr />
      <LineItems />
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(showInded));

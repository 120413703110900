import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, Field, ErrorMessage } from 'formik';
import { Button, Col, Row } from 'reactstrap';
import randInt from '../../../../helpers/randInt';

const ReturnTrackingArrayField = ({
  returnedTrackingNumbers,
  values,
  isSubmitting,
}) => (
  <FieldArray
    name="returnedTrackingNumbers"
    render={({ remove, push }) => (
      <div className="form__form-group">
        {returnedTrackingNumbers && returnedTrackingNumbers.length > 0 ? (
          returnedTrackingNumbers.map((tn, index) => (
            <Row key={tn.id} className="my-3">
              <Col xs={7} md={6} lg={4}>
                <span className="form__form-group-label">Tracking #{index + 1}</span>
                <div className="form__form-group-field">
                  <Field
                    validateonblur="true"
                    type="text"
                    name={`returnedTrackingNumbers.${index}.value`}
                    className="form__input"
                    placeholder={`Tracking #${index + 1}...`}
                  />
                </div>
                <ErrorMessage
                  name={`returnedTrackingNumbers.${index}.value`}
                  component="div"
                  className="login__error"
                />
              </Col>
              <Col xs={5} md={3} lg={2}>
                <Button
                  style={{ marginTop: '23px', padding: '8px' }}
                  block
                  onClick={() => { if (values.returnedTrackingNumbers.length > 1) remove(index); }}
                  disabled={isSubmitting}
                  outline
                >
                  Remove
                </Button>
              </Col>
            </Row>
          ))
        ) : null }
        <Button
          outline
          color="primary"
          disabled={isSubmitting}
          onClick={() => push({ id: randInt(), value: '' })}
        >
          Add Tracking
        </Button>
      </div>
    )}
  />
);


ReturnTrackingArrayField.propTypes = {
  returnedTrackingNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.value,
    }),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default ReturnTrackingArrayField;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import BuildListInformation from './components/BuildListInformation';
import ContentsInformation from './components/ContentsInformation';
import ShowContainer from '../../shared/components/show/showContainer';

const BuildListsShow = () => (
  <Container>
    <ShowContainer>
      <BuildListInformation />
      <hr />
      <ContentsInformation />
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(BuildListsShow));

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import { formatDescriptionArrayToString, formatKitStockType } from '../../helpers/resourceItem';

class KitsIndexContainer extends Component {
  createRows = list => (
    list.map((item) => {
      const {
        buildOrderId,
        id,
        ssid,
        status,
        type,
      } = item;

      return (
        <tr key={id}>
          <td>{type}</td>
          <td>{id}</td>
          <td>{ssid}</td>
          <td>{ formatKitStockType(status)}</td>
          <td>{buildOrderId}</td>
          <td><Link to={`/inventory/kits/${id}`}>Show</Link></td>
        </tr>
      );
    })
  );

  buildBadgeList = (filter, defaultFilter) => {
    const {
      buildOrderId, buildListId, search, status,
    } = filter;
    const {
      buildOrderId: defaultBuildOrderId,
      buildListId: defaultBuildListId,
      search: defaultSearch,
      status: defaultStatus,
    } = defaultFilter;

    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case 'buildOrderId':
          if (buildOrderId && buildOrderId !== defaultBuildOrderId) {
            badgeList.push(
              {
                order: 3,
                title: 'Batch Id',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('buildListId'):
          if (buildListId && buildListId !== defaultBuildListId) {
            badgeList.push(
              {
                order: 4,
                title: 'Kit Type',
                descFilterable: 'bundles',
                filterKey: key,
              },
            );
          }
          break;
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('status'):
          if (status && status !== defaultStatus) {
            const baseDesc = filter[key];
            const builtDesc = [];
            if (baseDesc.includes('a_stock') && baseDesc.includes('a_stock_assigned')) {
              builtDesc.push('A-Stock(with Assigned)');
            } else if (baseDesc.includes('a_stock') && !baseDesc.includes('a_stock_assigned')) {
              builtDesc.push('A-Stock(w/o Assigned)');
            } else if (!baseDesc.includes('a_stock') && baseDesc.includes('a_stock_assigned')) {
              builtDesc.push('A-Stock(only Assigned)');
            }

            if (baseDesc.includes('untested_stock')) {
              builtDesc.push('Untested');
            }

            if (baseDesc.includes('field_stock')) {
              builtDesc.push('Field');
            }

            if (baseDesc.includes('sunset_stock')) {
              builtDesc.push('Sunset');
            }

            const desc = formatDescriptionArrayToString(builtDesc);
            badgeList.push(
              {
                order: 2,
                title: 'Status',
                desc,
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });

    return badgeList;
  }

  render() {
    const tableProps = {
      pageTitle: 'Inventory Kits',
      searchPlaceholder: 'Search by Kit ID or SSID...',
      heads: [
        { key: 'type', name: 'Type' },
        { key: 'id', name: 'ID' },
        { key: 'ssid', name: 'SSID' },
        { key: 'status', name: 'Status' },
        { key: 'buildOrderId', name: 'Batch ID' },
        { key: 'info', name: 'Info' },
      ],
      filterForm: [
        {
          type: 'CheckBoxGroup',
          groupLabel: 'Stock Types',
          filterKey: 'status',
          options: [
            { value: 'a_stock', label: 'A-Stock' },
            { value: 'a_stock_assigned', label: 'A-Stock Assigned' },
            { value: 'untested_stock', label: 'Untested Stock' },
            { value: 'field_stock', label: 'Field Stock' },
            { value: 'sunset_stock', label: 'Sunset Stock' },
          ],
        },
        {
          type: 'Text',
          groupLabel: 'Batch ID',
          filterKey: 'buildOrderId',
          placeholder: 'Search by Batch ID...',
        },
        {
          type: 'Select',
          groupLabel: 'Bundle Type',
          filterKey: 'buildListId',
          placeholder: 'Filter by Bundle...',
          optionsKey: 'prebuiltBundles',
        },
      ],
      createRows: this.createRows,
      buildBadgeList: this.buildBadgeList,
    };

    return (
      <IndexTableContainer {...tableProps} />
    );
  }
}

export default KitsIndexContainer;

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { formatBuildOrderStatus } from '../../../helpers/resourceItem';

const BuildOrderInformation = ({
  resourceitem: {
    id,
    buildListName,
    status,
    orderSize,
    startDate,
    finishDate,
  },
}) => (
  <Row>
    <Col md={12}>
      <h3 className="page-title mb-0">
        Batch Detail Page
      </h3>
      {
        // <Button className="project-summary__btn mr-4" outline size="sm">Edit</Button>
      }
    </Col>
    <Col md={12}>
      <div className="project-summary">
        <Row>
          <Col lg={6}>
            <table className="project-summary__info ml-2">
              <tbody>
                <tr>
                  <th>Type:</th>
                  <td>{ buildListName }</td>
                </tr>
                <tr>
                  <th>Database ID:</th>
                  <td>{ id }</td>
                </tr>
                <tr>
                  <th>Order Status:</th>
                  <td>{ formatBuildOrderStatus(status) }</td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col lg={6}>
            <table className="project-summary__info ml-2">
              <tbody>
                <tr>
                  <th>Start Date:</th>
                  <td>{ startDate }</td>
                </tr>
                <tr>
                  <th>Finish Date:</th>
                  <td>{ finishDate }</td>
                </tr>
                <tr>
                  <th>Order Size:</th>
                  <td>{ orderSize }</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    </Col>
  </Row>
);

BuildOrderInformation.propTypes = {
  resourceitem: PropTypes.shape({
    id: PropTypes.number,
  }),
};

BuildOrderInformation.defaultProps = {
  resourceitem: {},
};

export default BuildOrderInformation;

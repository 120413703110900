import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';

import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { sendForgotPassword } from '../../../redux/actions/authActions';

import { getAuthenticated, getErrors, getSuccessMessage } from '../../../redux/reducers/authReducer';

class LogInForm extends PureComponent {
  static propTypes = {
    authenticated: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    successMessage: PropTypes.string,
  };

  static defaultProps= {
    errors: [],
    successMessage: '',
  }

  componentDidMount() {
    const { authenticated, history } = this.props;
    if (authenticated) history.push('/inventory/items');
  }

  render() {
    const { errors: errorMessages, successMessage } = this.props;

    return (
      <Formik
        initialValues={{ email: '' }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            const { dispatch, history } = this.props;
            dispatch(sendForgotPassword(values, history));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="form">
            <div className="form__form-group">
              <span className="form__form-group-label">Email</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon input-icon-left">
                  <AccountOutlineIcon />
                </div>
                <Field type="email" name="email" className="login__input" />
              </div>
              <ErrorMessage name="email" component="div" className="login__error" />
            </div>
            <div className="login__error">
              { errorMessages.map(e => <div key={e}>{e}</div>) }
            </div>
            <div className="login__success">
              { successMessage && <div>{successMessage}</div> }
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-secondary account__btn account__btn--small account__login__btn"
            >
              Reset My Password
            </button>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => ({
  authenticated: getAuthenticated(state),
  errors: getErrors(state),
  successMessage: getSuccessMessage(state),
});

export default withRouter(connect(mapStateToProps)(LogInForm));

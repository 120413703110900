export const formatDescriptionArrayToString = (filterValue) => {
  const statusLength = filterValue.length;
  const valueString = [];
  filterValue.forEach((val, i) => {
    valueString.push(val);
    valueString.push(i < statusLength - 2 ? ', ' : '');
    valueString.push(i === statusLength - 2 ? ' & ' : '');
  });
  return valueString.join('');
};

export const formatType = type => (
  type === 'serial_number' ? 'SN' : 'EXP'
);

export const formatCurrency = number => (
  (number / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
);

export const formatNumber = number => (
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
);

export const formatBuildType = (buildType) => {
  let desc;
  switch (buildType) {
    case 'build_on_order':
      desc = 'Build On Order';
      break;
    case 'prebuilt':
      desc = 'Prebuilt';
      break;
    case 'network':
      desc = 'Prebuilt w/ Network';
      break;
      // no default
  }
  return desc;
};

export const formatRange = (ids) => {
  const asNumbers = ids.map(id => Number(id));
  const min = Math.min(...asNumbers);
  const max = Math.max(...asNumbers);
  return `${min} - ${max}`;
};

export const formatVendorStatus = (status) => {
  let desc;
  switch (status) {
    case 'processed':
      desc = 'Processed';
      break;
    case 'unprocessed':
      desc = 'Not Processed';
      break;
      // no default
  }
  return desc;
};

export const formatStockType = (status) => {
  let desc;
  switch (status) {
    case 'a_stock':
      desc = 'A-Stock';
      break;
    case 'a_stock_assigned':
      desc = 'A-Stock Assigned';
      break;
    case 'b_stock':
      desc = 'B-Stock';
      break;
    case 'b_stock_assigned':
      desc = 'B-Stock Assigned';
      break;
    case 'defective_stock':
      desc = 'Defective Stock';
      break;
    case 'shipment_stock':
      desc = 'Shipment Stock';
      break;
    case 'field_stock':
      desc = 'Field Stock';
      break;
      // no default
  }
  return desc;
};

export const formatKitStockType = (status) => {
  let desc;
  switch (status) {
    case 'a_stock':
      desc = 'A-Stock';
      break;
    case 'a_stock_assigned':
      desc = 'A-Stock Assigned';
      break;
    case 'untested_stock':
      desc = 'Untested Stock';
      break;
    case 'field_stock':
      desc = 'Field Stock';
      break;
    case 'sunset_stock':
      desc = 'Sunset Stock';
      break;
      // no default
  }
  return desc;
};

export const formatShipmentStatus = (status) => {
  let desc;
  switch (status) {
    case 'fulfilled':
      desc = 'Fulfilled';
      break;
    case 'canceled':
      desc = 'Canceled';
      break;
    case 'processed':
      desc = 'Processed';
      break;
    case 'unprocessed':
      desc = 'Not Processed';
      break;
    case 'processed_incomplete':
      desc = 'Processed Incomplete';
      break;
    case 'unfulfilled':
      desc = 'Not Fulfilled';
      break;
      // no default
  }
  return desc;
};

export const formatBuildOrderStatus = (status) => {
  let desc;
  switch (status) {
    case 'planned':
      desc = 'Planned';
      break;
    case 'in_progress':
      desc = 'In Progress';
      break;
    case 'completed':
      desc = 'Completed';
      break;
      // no default
  }
  return desc;
};

export const sortAlphabeticallyBy = (list, key) => (
  list.sort((a, b) => {
    const textA = a[key].toUpperCase();
    const textB = b[key].toUpperCase();
    if (textA < textB) return -1;
    if (textA > textB) return 1;
    return 0;
  })
);

export const sortNumericallyBy = (list, key) => (
  list.sort((a, b) => ((a[key] > b[key]) ? 1 : -1))
);

export const splitList = (list, targetAmount) => {
  const shouldSplit = !targetAmount || list.length > targetAmount;
  if (!shouldSplit) return { left: list };
  const mid = Math.ceil(list.length / 2);
  return { left: list.slice(0, mid), right: list.slice(mid) };
};

export const isEmpty = obj => (
  Object.entries(obj).length === 0 && obj.constructor === Object
);

export const formatOrderStatus = (status) => {
  let desc;
  switch (status) {
    case 'fulfilled':
      desc = 'Fulfilled';
      break;
    case 'canceled':
      desc = 'Canceled';
      break;
    case 'unfulfilled':
      desc = 'Not Fulfilled';
      break;
    case 'fulfilled_partially_canceled':
      desc = 'Fulfilled and Partially Canceled';
      break;
    case 'partially_fulfilled':
      desc = 'Partially Fulfilled';
      break;
    case 'unfulfilled_partially_canceled':
      desc = 'Not Fulfilled and Partially Canceled';
      break;
    case 'partially_fulfilled_partially_canceled':
      desc = 'Partially Fulfilled and Partially Canceled';
      break;
    case 'processed':
      desc = 'Processed';
      break;
    case 'unprocessed':
      desc = 'Not Processed';
      break;
    case 'processed_partially_canceled':
      desc = 'Processed and Partially Canceled';
      break;
    case 'partially_processed':
      desc = 'Partially Processed';
      break;
    case 'unprocessed_partially_canceled':
      desc = 'Not Processed and Partially Canceled';
      break;
    case 'partially_processed_partially_canceled':
      desc = 'Partially Processed and Partially Canceled';
      break;
    // no default
  }
  return desc;
};

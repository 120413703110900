export const API = 'API';
export const API_START = 'API_START';
export const API_END = 'API_END';
export const ACCESS_DENIED = 'ACCESS_DENIED';
export const API_ERRORS = 'API_ERRORS';

export const apiStart = label => ({
  type: API_START,
  payload: label,
});

export const apiEnd = label => ({
  type: API_END,
  payload: label,
});

export const accessDenied = url => ({
  type: ACCESS_DENIED,
  payload: {
    url,
  },
});

export const apiErrors = error => ({
  type: API_ERRORS,
  error,
});

export function apiAction({
  url = '',
  method = 'GET',
  params = {},
  onSuccess = null,
  onFailure = null,
  setLoading = true,
  resource = '',
  history = null,
  successHistoryPushTo = null,
}) {
  return {
    type: API,
    payload: {
      url,
      method,
      params,
      onSuccess,
      onFailure,
      setLoading,
      resource,
      history,
      successHistoryPushTo,
    },
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import { sortNumericallyBy } from '../../../../helpers/resourceItem';

const VendorOrderLineItems = ({ resourceitem: { lineItems } }) => {
  const sorted = sortNumericallyBy(lineItems, 'id');
  const displayLineItems = sorted.map((li) => {
    const {
      id, itemId, name, quantity,
    } = li;

    return (
      <tr key={`lineitem${id}`}>
        <td>{id}</td>
        <td>{name}</td>
        <td>{quantity}</td>
        <td>{itemId}</td>
      </tr>
    );
  });

  return (
    <Row>
      <Col xs={12}>
        <h3 className="page-title mb-0">
          Line Items
        </h3>
      </Col>
      <Col md={12}>
        <Card className="pb-0">
          <CardBody className="pt-3 pb-0">
            <div className="project-summary">
              <div className="table-responsive">
                <table className="table--bordered table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Item</th>
                      <th>Quantity</th>
                      <th>Item ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayLineItems}
                  </tbody>
                </table>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

VendorOrderLineItems.propTypes = {
  resourceitem: PropTypes.shape({
    lineItems: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        itemId: PropTypes.number,
        name: PropTypes.string,
        quantity: PropTypes.number,
      }),
    ),
  }),
};

VendorOrderLineItems.defaultProps = {
  resourceitem: {},
};

export default VendorOrderLineItems;

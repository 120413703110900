import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import RadioButton from './RadioButton';
import randInt from '../../../helpers/randInt';

class RadioButtonGroup extends React.Component {
  static propTypes = {
    error: PropTypes.string,
    id: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ).isRequired,
    touched: PropTypes.bool,
    value: PropTypes.string.isRequired,
  }

  static defaultProps = {
    error: '',
    onBlur: () => {},
    touched: false,
  }

  handleChange = (event) => {
    const target = event.currentTarget;
    const { value, id, onChange } = this.props;
    const valueArray = [...value] || [];

    if (target.checked) {
      valueArray.push(target.id);
    } else {
      valueArray.splice(valueArray.indexOf(target.id), 1);
    }

    onChange(id, valueArray);
  };

  handleBlur = () => {
    const { onBlur, id } = this.props;
    onBlur(id, true);
  };

  buildChildren = () => {
    const {
      id,
      options,
    } = this.props;

    return options.map((opt) => {
      const { label, value } = opt;
      const hasValue = !!value;
      const identifier = hasValue ? value : randInt();
      return (
        <div className="form__form-group-field" key={identifier}>
          <Field
            component={RadioButton}
            name={id}
            id={identifier}
            label={label}
            value={value}
          />
        </div>
      );
    });
  }

  render() {
    const { error, touched } = this.props;

    return (
      <fieldset>
        {this.buildChildren()}
        {touched && error}
      </fieldset>
    );
  }
}

export default RadioButtonGroup;

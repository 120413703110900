import React, { Component, Fragment } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import LoadingAnimation from '../../shared/components/general/LoadingAnimation';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import AppRouter from './AppRouter';
import store from './store';
import ScrollToTop from './ScrollToTop';
import { foundTokenSuccess, getCurrentUser } from '../../redux/actions/authActions';

class App extends Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    this.setState({ loaded: true });
    const token = localStorage.getItem('token');
    if (token) {
      store.dispatch(foundTokenSuccess());
      store.dispatch(getCurrentUser());
    }
  }

  render() {
    const { loaded } = this.state;
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <Fragment>
              { !loaded && <LoadingAnimation /> }
              { loaded && <AppRouter /> }
            </Fragment>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default hot(module)(App);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import searchApiRequest, {
  updatePageAndSearch as updateApiPageAndSearch,
  updateFilterAndSearch as updateApiFilterAndSearch,
} from '../../../redux/actions/indexActions';

import {
  getErrors, getFilter, getList, getDefaultFilter,
} from '../../../redux/reducers/indexReducer';
import { getApiPending } from '../../../redux/reducers/apiReducer';
import { getFilterables } from '../../../redux/reducers/authReducer';
import IndexTableDisplay from './IndexTableDisplay';

class IndexTableContainer extends Component {
  static propTypes = {
    pendingRequest: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    // eslint-disable-next-line react/forbid-prop-types
    filter: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    filterables: PropTypes.object.isRequired,
    // eslint-disable-next-line
    defaultFilter: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
    heads: PropTypes.arrayOf(PropTypes.object).isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType(
          [
            PropTypes.string,
            PropTypes.number,
          ],
        ),
      }),
    ),
    filterForm: PropTypes.arrayOf(PropTypes.object).isRequired,
    searchRequest: PropTypes.func.isRequired,
    updateFilterAndSearch: PropTypes.func.isRequired,
    updatePageAndSearch: PropTypes.func.isRequired,
    createRows: PropTypes.func.isRequired,
    newButtonText: PropTypes.string,
    searchPlaceholder: PropTypes.string.isRequired,
    pageTitle: PropTypes.string.isRequired,
  }

  static defaultProps = {
    errors: [],
    list: [],
    newButtonText: 'New',
  }

  constructor() {
    super();

    this.state = {
      displayFilter: false,
    };
  }

  componentDidMount() {
    const { searchRequest, history } = this.props;
    searchRequest(history);
  }

  handleToggleDisplayFilter = () => {
    const { displayFilter } = this.state;
    this.setState({ displayFilter: !displayFilter });
  }

  handleResetFilterKey = (key) => {
    const { updateFilterAndSearch: update, defaultFilter, history } = this.props;
    const resetValues = {};
    if (key instanceof Array) {
      // eslint-disable-next-line
      key.map(k => resetValues[k] = defaultFilter[k]);
    } else {
      resetValues[key] = defaultFilter[key];
    }
    update(resetValues, history);
  }

  render() {
    const {
      errors,
      createRows,
      list,
    } = this.props;
    const { displayFilter } = this.state;
    return (
      <div className="product-list-wrapper">
        { errors.length > 1 && errors.map(e => <span className="product-list-error">{e}</span>) }
        {
          !(errors.length > 1) && (
            <IndexTableDisplay
              onToggleDisplayFilter={this.handleToggleDisplayFilter}
              rows={createRows(list)}
              displayFilter={displayFilter}
              onResetFilterKey={this.handleResetFilterKey}
              {...this.props}
            />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { history } = ownProps;
  return {
    errors: getErrors(history, state),
    defaultFilter: getDefaultFilter(history),
    filter: getFilter(history, state),
    filterables: getFilterables(state),
    list: getList(history, state),
    pendingRequest: getApiPending(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  searchRequest: searchApiRequest,
  updateFilterAndSearch: updateApiFilterAndSearch,
  updatePageAndSearch: updateApiPageAndSearch,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IndexTableContainer));

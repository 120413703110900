import { apiAction } from './apiActions';
import setResourceData from '../../helpers/setResourceData';

export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_ERRORS = 'SEARCH_ERRORS';
export const UPDATE_SEARCH_FILTER = 'UPDATE_SEARCH_FILTER';

export function searchSuccess(data, resource) {
  const { list, meta } = data;
  return {
    type: SEARCH_SUCCESS,
    resource,
    list,
    searchMeta: meta,
  };
}

export function searchErrors(errors) {
  return {
    type: SEARCH_ERRORS,
    errors,
  };
}

export function updateSearchFilter(filter, resource) {
  return {
    type: UPDATE_SEARCH_FILTER,
    filter,
    resource,
  };
}

export default function searchRequest(history) {
  const resourceData = setResourceData(history);
  const { resource, resourceExtension } = resourceData;
  return apiAction({
    resource,
    method: 'POST',
    params: { searchRequest: true },
    url: `/${resourceExtension}/search`,
    onSuccess: searchSuccess,
    history,
  });
}

export function updateFilterAndSearch(newFilter, history) {
  const resourceData = setResourceData(history);
  const { resource } = resourceData;
  return (dispatch, getState) => {
    const { filter } = getState().index[resource];
    const mergedFilter = { ...filter, ...newFilter, page: 1 };
    dispatch(updateSearchFilter(mergedFilter, resource));
    dispatch(searchRequest(history));
  };
}

export function updatePageAndSearch(newPage, history) {
  const resourceData = setResourceData(history);
  const { resource } = resourceData;
  return (dispatch, getState) => {
    const { filter } = getState().index[resource];
    const mergedFilter = { ...filter, page: newPage };
    dispatch(updateSearchFilter(mergedFilter, resource));
    dispatch(searchRequest(history));
  };
}

import React from 'react';
import PropTypes from 'prop-types';

const PaginationInfoDisplay = (props) => {
  const {
    searchMeta: {
      offset,
      limit,
      totalResults,
    },
  } = props;

  const formattedTotal = totalResults && totalResults.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const offsetLowDesc = offset + 1;
  const displayLimit = offset + limit;
  const offsetUpperDesc = displayLimit < totalResults ? displayLimit : totalResults;
  return (
    <div className="pagination__wrap">
      <div className="pagination-info">
        <span>
          Showing {offsetLowDesc} to {offsetUpperDesc} of {formattedTotal} total results.
        </span>
      </div>
    </div>
  );
};

PaginationInfoDisplay.propTypes = {
  searchMeta: PropTypes.shape({
    limit: PropTypes.number,
    totalResults: PropTypes.number,
    offset: PropTypes.number,
  }),
};

PaginationInfoDisplay.defaultProps = {
  searchMeta: {
    limit: 25,
    totalResults: 25,
    offset: 0,
  },
};

export default PaginationInfoDisplay;

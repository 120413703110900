import React from 'react';
import PropTypes from 'prop-types';

import PaginationInfoContainer from './PaginationInfoContainer';

const PaginationDisplay = (props) => {
  const {
    history,
    updatePageAndSearch,
    searchMeta: { page, totalPages },
  } = props;

  const pageNumber = Number(page);
  if (totalPages <= 1) return <div />;

  const previousDisabled = pageNumber === 1 ? 'disabled' : '';
  const nextDisabled = pageNumber === totalPages ? 'disabled' : '';

  const min = 1;
  let length = 5;
  if (length > totalPages) length = totalPages;

  let start = page - Math.floor(length / 2);
  start = Math.max(start, min);
  start = Math.min(start, min + totalPages - length);

  const pageRange = Array.from({ length }, (_, i) => (start + i))
    .filter(value => !Number.isNaN(value));

  const pages = pageRange.map((num) => {
    const currentPage = pageNumber === num;
    return (
      <li
        className={`pagination__item page-item ${currentPage ? 'active' : ''}`}
        key={`pagination${num}`}
      >
        <button
          type="button"
          className="pagination__link page-link"
          onClick={() => {
            if (!currentPage) {
              updatePageAndSearch(num, history);
            }
          }}
        >
          {num}
        </button>
      </li>
    );
  });

  return (
    <div className="d-flex justify-content-between mr-5 pagination__bottom-wrap">
      <PaginationInfoContainer {...props} />
      <div className="pagination__wrap mr-5">
        <nav className="pagination" aria-label="pagination">
          <ul className="pagination">
            <li className={`pagination__item page-item ${previousDisabled && 'disabled'}`}>
              <button
                type="button"
                className="pagination__link pagination__link--arrow page-link"
                onClick={() => {
                  if (!previousDisabled) {
                    updatePageAndSearch((pageNumber - 1), history);
                  }
                }}
              >
                <svg
                  className="mdi-icon pagination__link-icon"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
                </svg>
              </button>
            </li>
            {pages}
            <li className={`pagination__item page-item ${nextDisabled && 'disabled'}`}>
              <button
                type="button"
                className="pagination__link pagination__link--arrow page-link"
                onClick={() => {
                  if (!nextDisabled) {
                    updatePageAndSearch((pageNumber + 1), history);
                  }
                }}
              >
                <svg
                  className="mdi-icon pagination__link-icon"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

PaginationDisplay.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  searchMeta: PropTypes.shape({
    page: PropTypes.number,
    totalPages: PropTypes.number,
  }),
  updatePageAndSearch: PropTypes.func.isRequired,
};

PaginationDisplay.defaultProps = {
  searchMeta: {
    page: 1,
    totalPages: 1,
  },
};

export default PaginationDisplay;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { apiAction } from '../../../redux/actions/apiActions';
import setResourceData from '../../../helpers/setResourceData';
import { isEmpty } from '../../../helpers/resourceItem';

class ShowContainer extends Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    // eslint-disable-next-line
    match: PropTypes.object,
    // eslint-disable-next-line
    location: PropTypes.object,
    // eslint-disable-next-line
    children: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.array.isRequired,
    ]),
  }

  constructor() {
    super();
    this.state = {
      resourceItem: {},
    };
  }

  componentDidMount() {
    const { dispatch, history: domHistory, match: { params } } = this.props;
    const resourceData = setResourceData(domHistory, params);
    const { id, resourceExtension } = resourceData;
    dispatch(
      apiAction(
        {
          method: 'GET',
          url: `/${resourceExtension}/${id}`,
          onSuccess: (res) => {
            this.setState({ resourceItem: res });
            return { type: 'FORM_REQUEST' };
          },
          onFailure: () => ({ type: 'FORM_REQUEST' }),
          domHistory,
        },
      ),
    );
  }

  cloneChildren() {
    const { children } = this.props;
    const { resourceItem } = this.state;
    return React.Children.map(children, child => React.cloneElement(child, { resourceitem: resourceItem }));
  }

  renderChildren() {
    const { resourceItem } = this.state;
    if (!isEmpty(resourceItem)) return this.cloneChildren();
    return null;
  }

  render() {
    return <Container>{this.renderChildren()}</Container>;
  }
}

export default withRouter(connect()(ShowContainer));

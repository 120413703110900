import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import { formatType, formatNumber } from '../../helpers/resourceItem';

class ItemsIndexContainer extends Component {
  createRows = list => (
    list.map((item) => {
      const {
        id,
        aStock,
        aStockAssigned,
        bStock,
        bStockAssigned,
        baseContentId,
        defectiveStock,
        itemType,
        name,
      } = item;

      return (
        <tr key={baseContentId}>
          <td>{name}</td>
          <td>{baseContentId}</td>
          <td>{formatType(itemType)}</td>
          <td>{formatNumber(aStock)}</td>
          <td>{formatNumber(aStockAssigned)}</td>
          <td>{itemType === 'expensed' ? '-' : formatNumber(bStock)}</td>
          <td>{itemType === 'expensed' ? '-' : formatNumber(bStockAssigned)}</td>
          <td>{itemType === 'expensed' ? '-' : formatNumber(defectiveStock)}</td>
          <td><Link to={`/inventory/items/${id}`}>Show</Link></td>
        </tr>
      );
    })
  );

  buildBadgeList = (filter, defaultFilter) => {
    const { itemType, search } = filter;
    const { defaultItemType, defaultSearch } = defaultFilter;
    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('itemType'):
          if (itemType && itemType !== defaultItemType) {
            badgeList.push(
              {
                order: 2,
                title: 'Item Type',
                desc: filter[key].replace('_', ' '),
                filterKey: key,
              },
            );
          }
          break;
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });
    return badgeList;
  }

  render() {
    const tableProps = {
      pageTitle: 'Inventory Items',
      searchPlaceholder: 'Search by Item Name or Content ID...',
      heads: [
        { key: 'name', name: 'Name' },
        { key: 'id', name: 'Content ID' },
        { key: 'itemType', name: 'Item Type' },
        { key: 'a_stock', name: 'A-Stock' },
        { key: 'a_stock_assigned', name: 'A-Assigned' },
        { key: 'b_stock', name: 'B-Stock' },
        { key: 'b_stock_assigned', name: 'B-Assigned' },
        { key: 'defective_stock', name: 'Defective' },
        { key: 'information', name: 'Info' },
      ],
      filterForm: [
        {
          type: 'RadioButtonGroup',
          groupLabel: 'Item Type',
          filterKey: 'itemType',
          options: [
            { value: '', label: 'All' },
            { value: 'expensed', label: 'Expensed' },
            { value: 'serial_number', label: 'Serial Number' },
            { value: 'mailing_container', label: 'Mailing Container' },
          ],
        },
      ],
      createRows: this.createRows,
      buildBadgeList: this.buildBadgeList,
    };

    return (
      <IndexTableContainer {...tableProps} />
    );
  }
}

export default ItemsIndexContainer;

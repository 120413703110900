import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

const DatePickerField = ({
  name, value, onChange, placeholder, maxDate,
}) => (
  <div className="date-picker">
    <DatePicker
      isClearable
      maxDate={maxDate}
      placeholderText={placeholder}
      className="form__form-group-datepicker"
      selected={(value && new Date(value.replace(/-/g, '/'))) || null}
      onChange={val => onChange(name, (val && val.toISOString().substring(0, 10)))}
      dropDownMode="select"
    />
  </div>
);

DatePickerField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  maxDate: PropTypes.number,
};

DatePickerField.defaultProps = {
  value: null,
  maxDate: null,
};


export default DatePickerField;

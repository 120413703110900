import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import ReceivingShipmentInformation from './components/show/ReceivingShipmentInformation';
import ShowContainer from '../../shared/components/show/showContainer';

const Show = () => (
  <Container>
    <ShowContainer>
      <ReceivingShipmentInformation />
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(Show));

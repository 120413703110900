import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import BorderedTable from './BorderedTable';
import FilterBarSearchDisplay from './FilterBarSearchDisplay';
import FilterBarSideDisplay from './FilterBarSideDisplay';
import FilterBadgesDisplay from './FilterBadgesDisplay';
import PaginationInfoContainer from './PaginationInfoContainer';
import PaginationContainer from './PaginationContainer';
import PageHeader from '../pages/Header';

const IndexTableDisplay = ({
  pageTitle,
  ...props
}) => (
  <Container className="dashboard">
    <PageHeader title={pageTitle} />
    <FilterBarSearchDisplay {...props} />
    <FilterBarSideDisplay {...props} />
    <FilterBadgesDisplay {...props} />
    <PaginationInfoContainer {...props} />
    <BorderedTable {...props} />
    <PaginationContainer {...props} />
  </Container>
);

IndexTableDisplay.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
};

IndexTableDisplay.defaultProps = {
  list: [],
};

IndexTableDisplay.propTypes = {
  pageTitle: PropTypes.string.isRequired,
};

export default IndexTableDisplay;

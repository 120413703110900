import { apiAction } from './apiActions';
import { updateFilterAndSearch } from './indexActions';
import { getDefaultFilter } from '../reducers/indexReducer';

export const AUTHENTICATED = 'AUTHENTICATED';
export const UNAUTHENTICATED = 'UNAUTHENTICATED';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const AUTHENTICATION_SUCCESS_MESSAGE = 'AUTHENTICATION_SUCCESS_MESSAGE';
export const TOKEN_AUTHENTICATED = 'TOKEN_AUTHENTICATED';

export function foundTokenSuccess() {
  return {
    type: TOKEN_AUTHENTICATED,
  };
}

export function loginSuccess(data) {
  return {
    type: AUTHENTICATED,
    ...data,
  };
}

export function authFailure(errors) {
  const errorMessages = errors.map(e => e.message);
  return {
    type: AUTHENTICATION_ERROR,
    errors: errorMessages,
  };
}

export function authSuccessMessage(data) {
  return {
    type: AUTHENTICATION_SUCCESS_MESSAGE,
    successMessage: data.successMessage,
  };
}

export function loginAction({ email, password }, history) {
  return apiAction({
    method: 'POST',
    params: { email, password },
    url: '/login',
    onSuccess: loginSuccess,
    onFailure: authFailure,
    history,
    successHistoryPushTo: '/inventory/items',
  });
}

export function logOutAction(history) {
  localStorage.removeItem('token');
  history.push('/login');
  return ({ type: UNAUTHENTICATED });
}

export function getCurrentUser() {
  return apiAction({
    method: 'GET',
    url: '/users/current',
    onSuccess: loginSuccess,
    onFailure: authFailure,
  });
}

export function sendForgotPassword({ email }, history) {
  return apiAction({
    method: 'POST',
    params: { email },
    url: '/forgot_password',
    onSuccess: authSuccessMessage,
    onFailure: authFailure,
    history,
  });
}

export function sendResetPassword(params, history) {
  return apiAction({
    method: 'POST',
    params,
    url: '/reset_password',
    onFailure: authFailure,
    history,
    successHistoryPushTo: '/inventory/items',
  });
}

export function sendAcceptInvite(params, history) {
  return apiAction({
    method: 'POST',
    params,
    url: '/invites/accept_invite',
    onSuccess: loginSuccess,
    onFailure: authFailure,
    history,
  });
}

function switchAccountSuccess(data, _, history) {
  const defaultFilter = getDefaultFilter(history);
  return (dispatch) => {
    dispatch(loginSuccess(data));
    dispatch(updateFilterAndSearch(defaultFilter, history));
  };
}

export function switchAccount(accountId, history) {
  return apiAction({
    method: 'POST',
    params: { accountId },
    url: '/users/switch_account',
    onSuccess: switchAccountSuccess,
    onFailure: authFailure,
    history,
  });
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import { formatRange, formatDescriptionArrayToString, formatBuildOrderStatus } from '../../helpers/resourceItem';


class BuildOrdersIndexContainer extends Component {
  formatIdRange = (kitIds, kitSsids) => {
    if (kitSsids && kitSsids.length > 0) {
      return (
        <td>
          <div>ID {formatRange(kitIds)}</div>
          <div>SSID {formatRange(kitSsids)}</div>
        </td>
      );
    }
    return <td>ID {formatRange(kitIds)}</td>;
  };

  createRows = list => (
    list.map((item) => {
      const {
        orderSize,
        id,
        status,
        kitIds,
        kitSsids,
        buildListName: type,
      } = item;

      return (
        <tr key={id}>
          <td>{type}</td>
          <td>{id}</td>
          <td>{formatBuildOrderStatus(status)}</td>
          <td>{orderSize}</td>
          {this.formatIdRange(kitIds, kitSsids)}
          <td><Link to={`/inventory/batches/${id}`}>Show</Link></td>
        </tr>
      );
    })
  );

  buildBadgeList = (filter, defaultFilter) => {
    const {
      buildListId,
      search,
      status,
    } = filter;
    const {
      buildListId: defaultBuildListId,
      search: defaultSearch,
      status: defaultStatus,
    } = defaultFilter;

    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('buildListId'):
          if (buildListId && buildListId !== defaultBuildListId) {
            badgeList.push(
              {
                order: 3,
                title: 'Type',
                descFilterable: 'bundles',
                filterKey: key,
              },
            );
          }
          break;
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('status'):
          if (status && status !== defaultStatus) {
            const baseDesc = filter[key];
            const builtDesc = [];

            if (baseDesc.includes('planned')) {
              builtDesc.push('Planned');
            }

            if (baseDesc.includes('in_progress')) {
              builtDesc.push('In Progress');
            }

            if (baseDesc.includes('completed')) {
              builtDesc.push('Completed');
            }

            const desc = formatDescriptionArrayToString(builtDesc);
            badgeList.push(
              {
                order: 2,
                title: 'Stock Types',
                desc,
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });
    return badgeList;
  }

  render() {
    const tableProps = {
      pageTitle: 'Batches',
      searchPlaceholder: 'Search by ID...',
      heads: [
        { key: 'type', name: 'Type' },
        { key: 'id', name: 'ID' },
        { key: 'status', name: 'Status' },
        { key: 'orderSize', name: 'Order Size' },
        { key: 'idRange', name: 'ID Range' },
        { key: 'info', name: 'Info' },
      ],
      filterForm: [
        {
          type: 'CheckBoxGroup',
          groupLabel: 'Status',
          filterKey: 'status',
          options: [
            { value: 'planned', label: 'Planned' },
            { value: 'in_progress', label: 'In Progress' },
            { value: 'completed', label: 'Completed' },
          ],
        },
        {
          type: 'Select',
          groupLabel: 'Bundle Type',
          filterKey: 'buildListId',
          placeholder: 'Filter by Bundle...',
          optionsKey: 'prebuiltBundles',
        },
      ],
      createRows: this.createRows,
      buildBadgeList: this.buildBadgeList,
    };

    return (
      <IndexTableContainer {...tableProps} />
    );
  }
}

export default BuildOrdersIndexContainer;

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

const PageHeader = ({ title }) => (
  <Row>
    <Col xs={12}>
      <h3 className="page-title">{title}</h3>
    </Col>
  </Row>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageHeader;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Formik, Form } from 'formik';
import {
  Container, Col, Row, Button,
} from 'reactstrap';

import DatePicker from 'react-datepicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import LineItemsArrayField from './components/form/LineItemsArrayField';
import { getFilterables } from '../../redux/reducers/authReducer';
import Alert from '../../shared/components/general/Alert';
import SuccessModal from '../../shared/components/general/SuccessModal';
import TextField from '../../shared/components/form/TextField';

// import sendCreateRecord from '../../redux/actions/newFormActions';
import { apiAction } from '../../redux/actions/apiActions';
import setResourceData from '../../helpers/setResourceData';
import { toCamelCase, humanizeCamelCase } from '../../helpers/convertKeys';
import randInt from '../../helpers/randInt';

class ReceivingOrdersForm extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  static defaultProps = {
    items: [],
  }

  constructor() {
    super();
    this.state = {
      serverErrors: {},
      createSuccess: false,
    };
  }

  handleClearServerErrors = () => {
    this.setState({ serverErrors: {} });
  }

  render() {
    const initialValues = {
      vendor: '',
      poNumber: '',
      invoiceNumber: '',
      orderDate: '',
      lineItems: [
        {
          id: randInt(),
          itemId: '',
          quantity: '',
        },
      ],
    };

    const { serverErrors, createSuccess } = this.state;
    // eslint-disable-next-line
    const { history: domHistory, items, dispatch } = this.props;
    const resourceData = setResourceData(domHistory);
    const { resource, resourceExtension } = resourceData;
    const contentsList = [];
    if (items) {
      items.forEach(itm => contentsList.push({ label: itm.name, value: itm.id }));
    }

    return (
      <Container>
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const errors = {};
            if (!values.vendor) errors.vendor = 'Required';

            const allItemIds = values.lineItems.map(itm => itm.itemId);
            const unfilteredDuplicates = allItemIds.filter((item, index) => allItemIds.indexOf(item) !== index);
            const duplicateItemIds = unfilteredDuplicates.filter(itm => itm !== '');
            const lineItemErrors = [];
            values.lineItems.forEach((itm) => {
              const indexErrors = {};
              if (itm.quantity === '' || Number(itm.quantity) < 1) indexErrors.quantity = 'Min 1 Required';
              if (itm.itemId === '') indexErrors.itemId = 'At least one stock item must be selected';
              if (duplicateItemIds.includes(itm.itemId)) indexErrors.itemId = 'Duplicate items';
              if (Object.keys(indexErrors).length > 0) lineItemErrors.push(indexErrors);
              lineItemErrors.push(indexErrors);
            });

            const hasLineErrors = lineItemErrors.map(e => (
              Object.entries(e).length !== 0 && e.constructor === Object
            )).includes(true);
            if (hasLineErrors) errors.lineItems = lineItemErrors;

            return errors;
          }}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            setTimeout(() => {
              dispatch(
                apiAction(
                  {
                    resource,
                    method: 'POST',
                    params: values,
                    url: `/${resourceExtension}`,
                    onSuccess: () => {
                      this.setState({ createSuccess: true });
                      setTimeout(() => { domHistory.goBack(); }, 1000);
                      return { type: 'FORM_REQUEST' };
                    },
                    onFailure: (errors) => {
                      const fieldErrors = {};
                      errors.forEach((e) => { fieldErrors[toCamelCase(e.field)] = e.message; });
                      this.setState({ serverErrors: fieldErrors });
                      setErrors(fieldErrors);
                      return { type: 'FORM_REQUEST' };
                    },
                    domHistory,
                  },
                ),
              );
              setSubmitting(false);
            }, 400);
          }}
        >
          {({
            isSubmitting, values, touched, errors, setFieldValue, setFieldTouched,
          }) => (
            <Form className="form">
              <SuccessModal
                isOpen={createSuccess}
                title="Receiving Order Successfully Created!"
                colored
                btn="Success"
                message={`You will be redirected back to the ${resourceExtension} index page.`}
              />
              <div className="col-md-12">
                <h3 className="page-title">
                  New Receiving Order Form
                </h3>
                <h3 className="page-subhead subhead">
                  Use this form to create a new vendor order.
                </h3>
              </div>
              <div className="customizer__form-group-wrap no-bottom-line col-md-12" style={{ width: '100%' }}>
                <div className="customizer__form-group-title-wrap mb-3">
                  <h4>Vendor Information</h4>
                </div>
                <div className="mx-3">
                  <Row style={{ width: '100%' }}>
                    <Col lg={6}>
                      <TextField
                        label="Vendor"
                        fieldName="vendor"
                        placeholder="Vendor..."
                      />
                    </Col>
                    <Col lg={6}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Order Date</span>
                        <div className="form__form-group-field">
                          <div className="date-picker">
                            <DatePicker
                              isClearable
                              placeholderText="Order Date"
                              className="form__form-group-datepicker"
                              selected={
                                (
                                  values.orderDate
                                  && new Date(values.orderDate.replace(/-/g, '/'))
                                )
                                || null
                              }
                              onChange={val => (
                                setFieldValue(
                                  'orderDate',
                                  (val && val.toISOString().substring(0, 10)),
                                )
                              )}
                              dropDownMode="select"
                            />
                          </div>
                          <div className="form__form-group-icon input-icon-right">
                            <CalendarBlankIcon />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ width: '100%' }}>
                    <Col lg={6}>
                      <TextField
                        label="PO Number"
                        fieldName="poNumber"
                        placeholder="PO Number..."
                      />
                    </Col>
                    <Col lg={6}>
                      <TextField
                        label="Invoice Number"
                        fieldName="invoiceNumber"
                        placeholder="Invoice Number"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="customizer__form-group-wrap no-bottom-lin col-md-12" style={{ width: '100%' }}>
                <div className="customizer__form-group-title-wrap">
                  <h4>Line Items</h4>
                </div>
                <div className="mx-3">
                  <Row style={{ width: '100%' }}>
                    <Col>
                      <LineItemsArrayField
                        items={contentsList}
                        fieldName="vendorShipments"
                        values={values}
                        touched={touched}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        isSubmitting={isSubmitting}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="customizer__form-group-wrap no-bottom-line col-md-12" style={{ width: '100%' }}>
                <div className="ml-3 mr-5">
                  <Alert
                    color="danger"
                    className="alert--bordered"
                    icon
                    visible={Object.keys(serverErrors).length > 0}
                    onDismiss={this.handleClearServerErrors}
                  >
                    <p>
                      <span className="bold-text mr-2">
                        Warning!
                      </span>
                      One or more errors are preventing this record from being saved!
                    </p>
                    {
                      Object.keys(serverErrors).map(errorKey => (
                        <p className="ml-2" key={`error-${errorKey}`}>
                          <b className="mr-1">{humanizeCamelCase(errorKey)}:</b>
                          { serverErrors[errorKey] }
                        </p>
                      ))
                    }
                  </Alert>
                  <Row>
                    <Col xs={6}>
                      <Button
                        block
                        color="secondary"
                        disabled={isSubmitting}
                        onClick={domHistory.goBack}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col xs={6}>
                      <Button
                        block
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Create New Receiving Order
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  items: getFilterables(state).items,
});


export default withRouter(connect(mapStateToProps)(ReceivingOrdersForm));

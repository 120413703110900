import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonToolbar,
  Col,
  Row,
} from 'reactstrap';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import FilterIcon from 'mdi-react/FilterIcon';
import PlusIcon from 'mdi-react/PlusIcon';

import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';

import { Link } from 'react-router-dom';

export default class FilterBarSearchDisplay extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
    searchPlaceholder: PropTypes.string.isRequired,
    filter: PropTypes.PropTypes.shape({
      search: PropTypes.string.isRequired,
    }),
    onToggleDisplayFilter: PropTypes.func.isRequired,
    updateFilterAndSearch: PropTypes.func.isRequired,
    validSearchMin: PropTypes.number,
    newButton: PropTypes.bool,
    newButtonText: PropTypes.string,
    newFormUri: PropTypes.string,
  }

  static defaultProps = {
    filter: {
      search: '',
    },
    validSearchMin: 4,
    newButton: false,
    newButtonText: '',
    newFormUri: '',
  }

  render() {
    const {
      filter,
      history,
      onToggleDisplayFilter,
      searchPlaceholder,
      newButton,
      newButtonText,
      newFormUri,
      validSearchMin,
    } = this.props;
    const { search: searchTerm } = filter;

    return (
      <Row>
        <Col>
          <Formik
            enableReinitialize
            validateOnBlur
            initialValues={{ search: searchTerm }}
            validate={(values) => {
              const errors = {};
              const { search } = values;
              const { length } = search;
              if (length !== 0 && length < validSearchMin) {
                errors.search = 'Search term must be at least 4 characters long.';
              } else if (length === 0) {
                errors.search = '';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                const { updateFilterAndSearch } = this.props;
                updateFilterAndSearch(values, history);
                setSubmitting(false);
              }, 400);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Row>
                  <Col lg={8} xl={7}>
                    <ButtonToolbar>
                      <div className="form__form-group">
                        <div className="form__form-group-field" lg={6}>
                          <Field
                            type="text"
                            name="search"
                            placeholder={searchPlaceholder}
                            style={{ width: '100%' }}
                          />
                          {
                            newButton
                              ? (
                                <Link to={newFormUri}>
                                  <button
                                    className="customizer__btn d-lg-none"
                                    type="button"
                                  >
                                    New
                                    <PlusIcon className="customizer__btn-icon" />
                                  </button>
                                </Link>
                              )
                              : null
                          }
                          <Button
                            type="submit"
                            color="primary"
                            className="icon d-none d-lg-block ml-2"
                            disabled={isSubmitting}
                            outline
                          >
                            <MagnifyIcon />
                          </Button>
                        </div>
                        <ErrorMessage name="search" component="div" className="login__error" />
                      </div>
                    </ButtonToolbar>
                  </Col>

                  <Col lg={4} xl={5}>
                    <div className="d-none d-lg-block">
                      <div className="d-flex flex-row-reverse">
                        {
                          newButton
                            ? (
                              <Link to={newFormUri}>
                                <Button
                                  className="icon icon--right"
                                  color="primary"
                                >
                                  <span
                                    style={{ color: 'white' }}
                                    className="d-xl-none"
                                  >
                                    New
                                    <PlusIcon />
                                  </span>
                                  <span
                                    style={{ color: 'white' }}
                                    className="d-none d-xl-block"
                                  >
                                    {newButtonText}
                                    <PlusIcon />
                                  </span>
                                </Button>
                              </Link>
                            )
                            : null
                        }
                        <Button
                          className="icon icon--right mr-2"
                          color="primary"
                          outline
                          onClick={onToggleDisplayFilter}
                        >
                          Filters
                          <FilterIcon />
                        </Button>
                      </div>
                    </div>

                    <Row className="d-lg-none">
                      <Col xs={6} className="pr-3">
                        <Button
                          block
                          className="icon icon--right"
                          color="primary"
                          outline
                          onClick={onToggleDisplayFilter}
                        >
                          Filters
                          <FilterIcon className="d-none d-sm-inline" />
                        </Button>
                      </Col>
                      <Col xs={6} className="pl-3">
                        <Button
                          block
                          className="icon icon--right"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Search <MagnifyIcon className="d-none d-sm-inline" />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    );
  }
}

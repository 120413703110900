import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldArray, Field, ErrorMessage } from 'formik';
import { Button, Col, Row } from 'reactstrap';
import SelectField from '../../../../shared/components/form/SelectField';
import randInt from '../../../../helpers/randInt';

class LineItemsArrayField extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    values: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    touched: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    errors: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
  }

  render() {
    const {
      items,
      values,
      touched,
      errors,
      setFieldValue,
      setFieldTouched,
      isSubmitting,
    } = this.props;
    const { lineItems } = values;
    const sortedItemsList = items.sort((a, b) => {
      const textA = a.label.toUpperCase();
      const textB = b.label.toUpperCase();
      if (textA < textB) return -1;
      if (textA > textB) return 1;
      return 0;
    });

    return (
      <FieldArray
        name="lineItems"
        render={({ remove, push }) => (
          <div className="form__form-group">
            {
              lineItems.length > 0 && lineItems.map((sr, index) => (
                <Row key={sr.id} className="border_bottom mb-2">
                  <Col className="my-2" md={2}>
                    <span className="form__form-group-label">Quantity</span>
                    <div className="form__form-group-field">
                      <Field
                        validateonblur="true"
                        type="text"
                        name={`lineItems.${index}.quantity`}
                        className="form__input"
                        placeholder="Quantity..."
                      />
                    </div>
                    <ErrorMessage
                      name={`lineItems.${index}.quantity`}
                      component="div"
                      className="login__error"
                    />
                  </Col>
                  <Col className="my-2" md={7}>
                    <div className="form__form-group-field">
                      <SelectField
                        label="Stock Item"
                        value={values.lineItems[index].itemId}
                        filterKey={`lineItems.${index}.itemId`}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        error={errors[`lineItems.${index}.itemId`]}
                        touched={touched[`lineItems.${index}.itemId`]}
                        placeholder="Select Incoming Item..."
                        options={sortedItemsList}
                      />
                    </div>
                  </Col>

                  <Col className="my-2" md={3}>
                    <Button
                      style={{ marginTop: '23px', padding: '8px' }}
                      block
                      onClick={() => { if (values.lineItems.length > 1) remove(index); }}
                      disabled={isSubmitting}
                      outline
                    >
                      Remove
                    </Button>
                  </Col>
                </Row>
              ))
            }
            <Button
              outline
              className="pull-right"
              color="primary"
              disabled={isSubmitting}
              onClick={() => push({
                id: randInt(),
                itemId: '',
                quantity: '',
              })}
            >
              Add Another Item
            </Button>
          </div>
        )}
      />
    );
  }
}

export default LineItemsArrayField;

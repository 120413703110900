import { SEARCH_SUCCESS, SEARCH_ERRORS, UPDATE_SEARCH_FILTER } from '../actions/indexActions';
import setResourceData from '../../helpers/setResourceData';

const defaultFilter = {
  buildLists: {
    page: 1,
    search: '',
    buildType: '',
  },
  buildOrders: {
    page: 1,
    search: '',
    status: ['planned', 'in_progress', 'completed'],
    buildListId: '',
  },
  salesOrders: {
    page: 1,
    search: '',
    fulfillmentType: 'new_order',
    shipmentsStatus: '',
    contentId: '',
  },
  salesShipments: {
    page: 1,
    search: '',
    fulfillmentType: 'new_order',
    status: '',
    contentId: '',
    processedDate: '',
    processedAfterDate: '',
    processedBeforeDate: '',
  },
  devices: {
    page: 1,
    status: ['a_stock', 'a_stock_assigned', 'b_stock', 'b_stock_assigned', 'defective_stock'],
    search: '',
    container: '',
    itemId: '',
  },
  inventoryLogs: {
    page: 1,
    search: '',
    itemId: '',
    event: '',
    toStockType: '',
    fromStockType: '',
    diff: '',
    processed: '',
    rolledBack: '',
  },
  items: {
    page: 1,
    search: '',
    itemType: '',
  },
  kits: {
    page: 1,
    status: ['a_stock', 'a_stock_assigned', 'untested_stock'],
    buildListId: '',
    buildOrderId: '',
    search: '',
  },
  returnOrders: {
    page: 1,
    search: '',
    fulfillmentType: 'return_order',
    shipmentsStatus: '',
    contentId: '',
  },
  returnShipments: {
    page: 1,
    search: '',
    fulfillmentType: 'return_order',
    status: '',
    contentId: '',
  },
  receivingOrders: {
    page: 1,
    search: '',
    searchReceivingShipments: '',
    itemId: '',
    status: ['processed', 'unprocessed', 'canceled'],
  },
  receivingShipments: {
    page: 1,
    search: '',
    vendorOrderId: '',
    itemId: '',
    status: ['processed', 'unprocessed', 'canceled'],
  },
};

const initialState = {
  buildLists: {
    errors: [],
    filter: defaultFilter.buildLists,
    list: [],
    pending: false,
    searchMeta: {},
  },
  buildOrders: {
    errors: [],
    filter: defaultFilter.buildOrders,
    list: [],
    pending: false,
    searchMeta: {},
  },
  salesOrders: {
    errors: [],
    filter: defaultFilter.salesOrders,
    list: [],
    pending: false,
    searchMeta: {},
  },
  salesShipments: {
    errors: [],
    filter: defaultFilter.salesShipments,
    list: [],
    pending: false,
    searchMeta: {},
  },
  devices: {
    errors: [],
    filter: defaultFilter.devices,
    list: [],
    searchMeta: {},
  },
  inventoryLogs: {
    errors: [],
    filter: defaultFilter.inventoryLogs,
    list: [],
    searchMeta: {},
  },
  items: {
    errors: [],
    filter: defaultFilter.items,
    list: [],
    searchMeta: {},
  },
  kits: {
    errors: [],
    filter: defaultFilter.kits,
    list: [],
    pending: false,
    searchMeta: {},
  },
  returnOrders: {
    errors: [],
    filter: defaultFilter.returnOrders,
    list: [],
    pending: false,
    searchMeta: {},
  },
  returnShipments: {
    errors: [],
    filter: defaultFilter.returnShipments,
    list: [],
    pending: false,
    searchMeta: {},
  },
  receivingOrders: {
    errors: [],
    filter: defaultFilter.receivingOrders,
    list: [],
    pending: false,
    searchMeta: {},
  },
  receivingShipments: {
    errors: [],
    filter: defaultFilter.receivingShipments,
    receivingShipments: [],
    pending: false,
    searchMeta: {},
  },
};

export default function indexReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_SUCCESS:
      return {
        ...state,
        [action.resource]: {
          ...state[action.resource],
          list: action.list,
          searchMeta: action.searchMeta,
        },
      };
    case SEARCH_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    case UPDATE_SEARCH_FILTER:
      return {
        ...state,
        [action.resource]: {
          ...state[action.resource],
          filter: action.filter,
        },
      };
    default:
      return state;
  }
}

export const getList = (history, state) => state.index[setResourceData(history).resource].list;
export const getSearchMeta = (history, state) => state.index[setResourceData(history).resource].searchMeta;
export const getErrors = (history, state) => state.index[setResourceData(history).resource].errors;
export const getFilter = (history, state) => state.index[setResourceData(history).resource].filter;
export const getDefaultFilter = history => defaultFilter[setResourceData(history).resource];

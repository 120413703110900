import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import CloseIcon from 'mdi-react/CloseIcon';

export default class FilterBadgesDisplay extends PureComponent {
  static propTypes = {
    onResetFilterKey: PropTypes.func.isRequired,
    buildBadgeList: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    filter: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    filterables: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    defaultFilter: PropTypes.object.isRequired,
  }

  findFilterableName = (obj) => {
    const { filterables, filter } = this.props;
    const { descFilterable, filterKey } = obj;
    const item = filterables[descFilterable].find(i => (i.id).toString() === filter[filterKey].toString());
    return item.name;
  };

  buildBadge = (obj) => {
    const { title, desc, filterKey } = obj;
    const { onResetFilterKey } = this.props;
    const hasDescription = !!desc;
    const description = hasDescription ? desc : this.findFilterableName(obj);
    return (
      <Badge
        className="m-1"
        color="success filter-badge"
        key={title}
      >
        {`${title}:`}
        <span style={{ paddingLeft: '10px' }}>
          { description }
        </span>
        <CloseIcon
          size={15}
          className="close__icon"
          onClick={() => onResetFilterKey(filterKey)}
        />
      </Badge>
    );
  }


  render() {
    const { buildBadgeList, filter, defaultFilter } = this.props;
    const badgeList = buildBadgeList(filter, defaultFilter);
    const orderedBadgeList = badgeList.sort((a, b) => ((a.order > b.order) ? 1 : -1));
    return (
      <div>
        {orderedBadgeList.map(obj => this.buildBadge(obj))}
      </div>
    );
  }
}

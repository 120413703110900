import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { formatKitStockType } from '../../../helpers/resourceItem';

const KitInformation = ({
  resourceitem: {
    id,
    type,
    status,

  },
}) => (
  <Row>
    <Col md={12}>
      <h3 className="page-title mb-0">
        Kit Detail Page
      </h3>
    </Col>
    <Col md={12}>
      <div className="project-summary">
        <Row>
          <Col lg={12}>
            <table className="project-summary__info ml-2">
              <tbody>
                <tr>
                  <th>Type:</th>
                  <td>{ type }</td>
                </tr>
                <tr>
                  <th>Database ID:</th>
                  <td>{ id }</td>
                </tr>
                <tr>
                  <th>Status:</th>
                  <td>{ formatKitStockType(status) }</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    </Col>
  </Row>
);

KitInformation.propTypes = {
  resourceitem: PropTypes.shape({
    id: PropTypes.number,
  }),
};

KitInformation.defaultProps = {
  resourceitem: {},
};

export default KitInformation;

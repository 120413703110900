import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { getResources } from '../../../redux/reducers/authReducer';

class SidebarContent extends Component {
  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { resources } = this.props;
    // const { changeToDark, changeToLight } = this.props;
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          {
            resources.includes('items')
              ? (
                <SidebarLink
                  title="Inventory Items"
                  svg="0342-tag"
                  route="/inventory/items"
                  onClick={this.hideSidebar}
                />
              )
              : null
          }
          {
            resources.includes('devices')
              ? (
                <SidebarLink
                  title="Devices"
                  svg="0330-barcode3"
                  route="/inventory/devices"
                  onClick={this.hideSidebar}
                />
              )
              : null
          }
          {
            resources.includes('logs')
              ? (
                <SidebarLink
                  title="Inventory Logs"
                  svg="0019-pencil4"
                  route="/inventory/logs"
                  onClick={this.hideSidebar}
                />
              )
              : null
          }
          {
            resources.includes('bundles')
              ? (
                <SidebarLink
                  title="Bundles"
                  svg="0204-clipboard-text"
                  route="/inventory/bundles"
                  onClick={this.hideSidebar}
                />
              )
              : null
          }
          {
            resources.includes('kits')
              ? (
                <SidebarLink
                  title="Built Kits"
                  svg="0040-color-sampler"
                  route="/inventory/kits"
                  onClick={this.hideSidebar}
                />
              )
              : null
          }
          {
            resources.includes('batches')
              ? (
                <SidebarLink
                  title="Batches"
                  svg="0119-hammer-wrench"
                  route="/inventory/batches"
                  onClick={this.hideSidebar}
                />
              )
              : null
          }
          {
            resources.includes('receiving')
              ? (
                <SidebarCategory title="Receiving Orders" svg="0218-document2">
                  {
                    resources.includes('receiving_orders')
                      ? (
                        <SidebarLink
                          title="Orders"
                          route="/orders/receiving-orders"
                          onClick={this.hideSidebar}
                        />
                      )
                      : null
                  }
                  {
                    resources.includes('receiving_shipments')
                      ? (
                        <SidebarLink
                          title="Shipments"
                          route="/orders/receiving-shipments"
                          onClick={this.hideSidebar}
                        />
                      )
                      : null
                  }
                </SidebarCategory>
              )
              : null
          }
          {
            resources.includes('sales')
              ? (
                <SidebarCategory title="Sales Orders" svg="0629-cube">
                  {
                    resources.includes('sales_order_batches')
                      ? (
                        <SidebarLink
                          title="Batch Orders"
                          route="/orders/batch-sales-orders"
                          onClick={this.hideSidebar}
                        />
                      )
                      : null
                  }
                  {
                    resources.includes('sales_orders')
                      ? (
                        <SidebarLink
                          title="Orders"
                          route="/orders/sales-orders"
                          onClick={this.hideSidebar}
                        />
                      )
                      : null
                  }
                  {
                    resources.includes('sales_shipments')
                      ? (
                        <SidebarLink
                          title="Shipments"
                          route="/orders/sales-shipments"
                          onClick={this.hideSidebar}
                        />
                      )
                      : null
                  }
                </SidebarCategory>
              )
              : null
          }
          {
            resources.includes('returns')
              ? (
                <SidebarCategory title="Sales Returns" svg="0630-3d-rotate">
                  {
                    resources.includes('return_orders')
                      ? (
                        <SidebarLink
                          title="Orders Index"
                          route="/orders/return-orders"
                          onClick={this.hideSidebar}
                        />
                      )
                      : null
                  }
                  {
                    resources.includes('return_shipments')
                      ? (
                        <SidebarLink
                          title="Shipments Index"
                          route="/orders/return-shipments"
                          onClick={this.hideSidebar}
                        />
                      )
                      : null
                  }
                </SidebarCategory>
              )
              : null
          }
        </ul>
      </div>
    );
  }
}

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
  resources: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = state => ({
  resources: getResources(state),
});

export default connect(mapStateToProps)(SidebarContent);

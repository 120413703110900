import React from 'react';
import { connect } from 'react-redux';

import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import { getApiPending } from '../../redux/reducers/apiReducer';
import { getAuthenticated } from '../../redux/reducers/authReducer';

import LoadingAnimation from '../../shared/components/general/LoadingAnimation';
import LogIn from '../LogIn/index';
import ForgotPassword from '../ForgotPassword/index';
import ResetPassword from '../ResetPassword/index';
import AcceptInvite from '../AcceptInvite/index';
import BuildListsIndex from '../BuildLists/index';
import BuildListsShow from '../BuildLists/showIndex';
import BuildOrdersIndex from '../BuildOrders/index';
import BuildOrdersShow from '../BuildOrders/showIndex';
import SalesOrdersIndex from '../SalesOrders/index';
import SalesOrdersForm from '../SalesOrders/formIndex';
import SalesOrdersShow from '../SalesOrders/showIndex';
import SalesShipmentsIndex from '../SalesShipments/index';
import SalesShipmentsShow from '../SalesShipments/showIndex';
import DevicesIndex from '../Devices/index';
import ItemsIndex from '../Items/index';
import ItemsShow from '../Items/showIndex';
import InventoryLogsIndex from '../InventoryLogs/index';
import InventoryLogsShow from '../InventoryLogs/showIndex';
import KitsIndex from '../Kits/index';
import KitsShow from '../Kits/showIndex';
import ReturnOrdersIndex from '../ReturnOrders/index';
import ReturnOrdersForm from '../ReturnOrders/formIndex';
import ReturnOrdersShow from '../ReturnOrders/showIndex';
import ReturnShipmentsIndex from '../ReturnShipments/index';
import ReturnShipmentsShow from '../ReturnShipments/showIndex';
import ReceivingOrdersIndex from '../ReceivingOrders/index';
import ReceivingOrdersShow from '../ReceivingOrders/showIndex';
import ReceivingOrdersForm from '../ReceivingOrders/formIndex';
import ReceivingShipmentsIndex from '../ReceivingShipments/index';
import ReceivingShipmentsShow from '../ReceivingShipments/showIndex';
import ReceivingShipmentsForm from '../ReceivingShipments/formIndex';
import NotFound from '../NotFound/index';

const Inventory = () => (
  <Switch>
    <Route exact path="/inventory/items" component={ItemsIndex} />
    <Route exact path="/inventory/items/:id" component={ItemsShow} />
    <Route exact path="/inventory/logs" component={InventoryLogsIndex} />
    <Route exact path="/inventory/logs/:id" component={InventoryLogsShow} />
    <Route exact path="/inventory/devices" component={DevicesIndex} />
    <Route exact path="/inventory/bundles" component={BuildListsIndex} />
    <Route exact path="/inventory/bundles/:id" component={BuildListsShow} />
    <Route exact path="/inventory/kits" component={KitsIndex} />
    <Route exact path="/inventory/kits/:id" component={KitsShow} />
    <Route exact path="/inventory/batches" component={BuildOrdersIndex} />
    <Route exact path="/inventory/batches/:id" component={BuildOrdersShow} />
    <Route component={NotFound} />
  </Switch>
);

const Orders = () => (
  <Switch>
    <Route exact path="/orders/receiving-orders" component={ReceivingOrdersIndex} />
    <Route exact path="/orders/receiving-orders/new" component={ReceivingOrdersForm} />
    <Route exact path="/orders/receiving-orders/:id" component={ReceivingOrdersShow} />
    <Route exact path="/orders/receiving-shipments" component={ReceivingShipmentsIndex} />
    <Route exact path="/orders/receiving-shipments/new" component={ReceivingShipmentsForm} />
    <Route exact path="/orders/receiving-shipments/:id" component={ReceivingShipmentsShow} />
    <Route exact path="/orders/sales-orders" component={SalesOrdersIndex} />
    <Route exact path="/orders/sales-orders/new" component={SalesOrdersForm} />
    <Route exact path="/orders/sales-orders/:id" component={SalesOrdersShow} />
    <Route exact path="/orders/sales-shipments" component={SalesShipmentsIndex} />
    <Route exact path="/orders/sales-shipments/:id" component={SalesShipmentsShow} />
    <Route exact path="/orders/return-orders/new" component={ReturnOrdersForm} />
    <Route exact path="/orders/return-orders/:id" component={ReturnOrdersShow} />
    <Route exact path="/orders/return-orders" component={ReturnOrdersIndex} />
    <Route exact path="/orders/return-shipments" component={ReturnShipmentsIndex} />
    <Route exact path="/orders/return-shipments/:id" component={ReturnShipmentsShow} />
    <Route component={NotFound} />
  </Switch>
);

const mapStateToProps = state => ({
  pending: getApiPending(state),
  authenticated: getAuthenticated(state),
});

const wrappedRoutes = connect(mapStateToProps)(({ pending, authenticated, ...props }) => (
  authenticated
    ? (
      <div className={`${pending ? 'loading-wrapper' : ''}`}>
        { pending && <LoadingAnimation /> }
        <Layout />
        <div className="container__wrap">
          <Switch>
            <Route path="/inventory" component={Inventory} />
            <Route path="/orders" component={Orders} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    )
    : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
));

const AppRouter = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/"><Redirect to="/inventory/items" /></Route>
        <Route exact path="/login" component={LogIn} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password/:resetPasswordToken" component={ResetPassword} />
        <Route path="/accept-invite/:inviteToken" component={AcceptInvite} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default AppRouter;

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';

import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { sendResetPassword } from '../../../redux/actions/authActions';

import { getAuthenticated, getErrors } from '../../../redux/reducers/authReducer';

class PasswordResetForm extends PureComponent {
  static propTypes = {
    authenticated: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    match: PropTypes.object.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps= {
    errors: [],
  }

  constructor() {
    super();
    this.state = {
      showPassword: false,
      showPasswordConfirmation: false,
    };
  }

  componentDidMount() {
    const { authenticated, history } = this.props;
    if (authenticated) history.push('/inventory/items');
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  showPasswordConfirmation = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPasswordConfirmation: !prevState.showPasswordConfirmation }));
  };

  render() {
    const { showPassword, showPasswordConfirmation } = this.state;
    const { errors: errorMessages, match } = this.props;
    const { resetPasswordToken } = match.params;

    return (
      <Formik
        initialValues={{ passwordConfirmation: '', password: '', resetPasswordToken }}
        validate={(values) => {
          const { password, passwordConfirmation } = values;
          const errors = {};
          if (!password) {
            errors.password = 'Required';
          }
          if (!passwordConfirmation) {
            errors.passwordConfirmation = 'Required';
          }
          const passwordValueExists = !!password;
          const passwordConfirmationValueExists = !!passwordConfirmation;
          if (passwordValueExists && passwordConfirmationValueExists && password !== passwordConfirmation) {
            errors.password = 'Password values do not match';
            errors.passwordConfirmation = 'Password values do not match';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            const { dispatch, history } = this.props;
            dispatch(sendResetPassword(values, history));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="form">
            <div className="form__form-group">
              <span className="form__form-group-label">Password</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon input-icon-left">
                  <KeyVariantIcon />
                </div>
                <Field
                  validateonblur="true"
                  type={`${showPassword ? 'text' : 'password'}`}
                  name="password"
                  className="login__input icon-right"
                />
                <button
                  className={`form__form-group-button login__input${showPassword ? ' active' : ''}`}
                  onClick={e => this.showPassword(e)}
                  type="button"
                ><EyeIcon />
                </button>
              </div>
              <ErrorMessage name="password" component="div" />
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">Password Confirmation</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon input-icon-left">
                  <KeyVariantIcon />
                </div>
                <Field
                  validateonblur="true"
                  type={`${showPasswordConfirmation ? 'text' : 'password'}`}
                  name="passwordConfirmation"
                  className="login__input icon-right"
                />
                <button
                  className={`form__form-group-button login__input${showPasswordConfirmation ? ' active' : ''}`}
                  onClick={e => this.showPasswordConfirmation(e)}
                  type="button"
                ><EyeIcon />
                </button>
              </div>
              <ErrorMessage name="passwordConfirmation" component="div" />
            </div>
            <div className="login__error">
              { errorMessages ? <div>{errorMessages}</div> : null }
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-secondary account__btn account__btn--small account__login__btn"
            >
              Reset My password
            </button>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => ({
  authenticated: getAuthenticated(state),
  errors: getErrors(state),
});

export default withRouter(connect(mapStateToProps)(PasswordResetForm));

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import { formatStockType } from '../../helpers/resourceItem';

class LogsIndexContainer extends Component {
  createRows = list => (
    list.map((inventoryLog) => {
      const {
        id,
        itemName,
        description,
        toStockType,
        fromStockType,
        diff,
      } = inventoryLog;

      return (
        <tr key={id}>
          <td>{id}</td>
          <td>{itemName}</td>
          <td>{diff}</td>
          <td>{formatStockType(fromStockType)}</td>
          <td>{formatStockType(toStockType)}</td>
          <td>{description}</td>
          <td><Link to={`/inventory/logs/${id}`}>Show</Link></td>
        </tr>
      );
    })
  );

  buildBadgeList = (filter, defaultFilter) => {
    const {
      search,
      itemId,
      event,
      toStockType,
      fromStockType,
      diff,
    } = filter;
    const {
      defaultSearch,
      defaultItemId,
      defaultEvent,
      defaultToStockType,
      defaultFromStockType,
      defaultDiff,
    } = defaultFilter;
    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('itemId'):
          if (itemId && itemId !== defaultItemId) {
            badgeList.push(
              {
                order: 2,
                title: 'Item Type',
                descFilterable: 'items',
                filterKey: key,
              },
            );
          }
          break;
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('event'):
          if (event && event !== defaultEvent) {
            badgeList.push(
              {
                order: 3,
                title: 'Event Type',
                descFilterable: 'logEvents',
                filterKey: key,
              },
            );
          }
          break;
        case ('toStockType'):
          if (toStockType && toStockType !== defaultToStockType) {
            badgeList.push(
              {
                order: 4,
                title: 'To Stock Type',
                descFilterable: 'validStockTypes',
                filterKey: key,
              },
            );
          }
          break;
        case ('fromStockType'):
          if (fromStockType && fromStockType !== defaultFromStockType) {
            badgeList.push(
              {
                order: 5,
                title: 'From Stock Type',
                descFilterable: 'validStockTypes',
                filterKey: key,
              },
            );
          }
          break;
        case ('diff'):
          if (diff && diff !== defaultDiff) {
            badgeList.push(
              {
                order: 6,
                title: 'Diff Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });
    return badgeList;
  }

  render() {
    const tableProps = {
      newButtonText: '',
      pageTitle: 'Inventory Logs',
      searchPlaceholder: 'Search by Log ID...',
      heads: [
        { key: 'id', name: 'ID' },
        { key: 'itemName', name: 'Item Name' },
        { key: 'diff', name: 'Amount' },
        { key: 'fromStockType', name: 'From' },
        { key: 'toStockType', name: 'To' },
        { key: 'description', name: 'Description' },
        { key: 'info', name: 'Info' },
      ],
      filterForm: [
        {
          type: 'Select',
          groupLabel: 'Item Type',
          filterKey: 'itemId',
          placeholder: 'Filter by Item Type...',
          optionsKey: 'items',
        },
        {
          type: 'Select',
          groupLabel: 'Event Type',
          filterKey: 'event',
          placeholder: 'Filter by Event Type...',
          optionsKey: 'logEvents',
        },
        {
          type: 'Select',
          groupLabel: 'To Stock Type',
          filterKey: 'toStockType',
          placeholder: 'Filter by To Stock Type...',
          optionsKey: 'validStockTypes',
        },
        {
          type: 'Select',
          groupLabel: 'From Stock Type',
          filterKey: 'fromStockType',
          placeholder: 'Filter by From Stock Type...',
          optionsKey: 'validStockTypes',
        },
        {
          type: 'Text',
          groupLabel: 'Diff Search',
          filterKey: 'diff',
          placeholder: 'Diff Quantity...',
        },
      ],
      createRows: this.createRows,
      buildBadgeList: this.buildBadgeList,
    };

    return (
      <IndexTableContainer {...tableProps} />
    );
  }
}

export default LogsIndexContainer;

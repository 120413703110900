import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { sortAlphabeticallyBy, formatDescriptionArrayToString } from '../../../helpers/resourceItem';
import randInt from '../../../helpers/randInt';

const ContentsInformation = ({ resourceitem: { kdvs, contents } }) => {
  const deviceIds = kdvs.map(kdv => kdv.variantId);
  const deviceContents = sortAlphabeticallyBy(contents.filter(itm => deviceIds.includes(itm.variantId)), 'name');
  const nonDeviceContents = sortAlphabeticallyBy(contents.filter(itm => !deviceIds.includes(itm.variantId)), 'name');
  const displayDeviceContents = deviceContents.map((itm, index) => {
    const { name, quantity, variantId } = itm;
    const serials = kdvs.filter(kdv => kdv.variantId === variantId).map(kdv => kdv.serialNumber);
    return (
      <tr key={randInt()}>
        <th>{index + 1}) </th>
        <td>{name} -- Qty {quantity}</td>
        <th>Serials: </th>
        <td>{formatDescriptionArrayToString(serials)}</td>
      </tr>
    );
  });

  const displayNonDeviceContents = nonDeviceContents.map((itm, index) => {
    const { name, quantity } = itm;
    return (
      <tr key={randInt()}>
        <th>{index + 1 + displayDeviceContents.length}) </th>
        <td>{name} -- Qty {quantity}</td>
      </tr>
    );
  });

  return (
    <Row>
      <Col md={12}>
        <h3 className="page-title mb-0">
          Kit Contents
        </h3>
      </Col>
      <Col md={12}>
        <div className="project-summary">
          <Row>
            <Col lg={12}>
              <table className="project-summary__info ml-2">
                <tbody>
                  {displayDeviceContents}
                  {displayNonDeviceContents}
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

ContentsInformation.propTypes = {
  resourceitem: PropTypes.shape({
    kdvs: PropTypes.arrayOf(PropTypes.object),
    contents: PropTypes.arrayOf(PropTypes.object),
  }),
};

ContentsInformation.defaultProps = {
  resourceitem: {
    kdvs: [],
    contents: [],
  },
};

export default ContentsInformation;

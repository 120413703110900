import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import VariantsInformation from './components/VariantsInformation';
import ItemInformation from './components/ItemInformation';
import ShowContainer from '../../shared/components/show/showContainer';

const ItemShow = () => (
  <Container>
    <ShowContainer>
      <ItemInformation />
      <hr />
      <VariantsInformation />
    </ShowContainer>
  </Container>
);

export default withRouter(connect()(ItemShow));

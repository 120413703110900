import React, { PureComponent } from 'react';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

export default class RadioButton extends PureComponent {
  static propTypes = {
    field: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    label: '',
    disabled: false,
    className: '',
  };

  render() {
    const {
      field: { name, value: formValue, onChange },
      id,
      value,
      label,
      className,
      disabled,
    } = this.props;

    const RadioButtonClass = classNames({
      'radio-btn': true,
      disabled,
    });

    return (
      <label
        className={`${RadioButtonClass}${className ? ` radio-btn--${className}` : ''}`}
        htmlFor={id}
      >
        <input
          id={id}
          name={name}
          type="radio"
          value={value}
          checked={value === formValue}
          onChange={onChange}
          disabled={disabled}
          className="radio-btn__radio"
        />
        <span className="radio-btn__radio-custom" />
        {className === 'button'
          ? (
            <span className="radio-btn__label-svg">
              <CheckIcon className="radio-btn__label-check" />
              <CloseIcon className="radio-btn__label-uncheck" />
            </span>
          ) : ''}
        <span className="radio-btn__label">{label}</span>
      </label>
    );
  }
}

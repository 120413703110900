import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import IndexTableContainer from '../../shared/components/tables/IndexTableContainer';
import randInt from '../../helpers/randInt';
import { formatVendorStatus } from '../../helpers/resourceItem';

class Index extends Component {
  constructor() {
    super();
    this.state = {
      expanded: [],
    };
  }

  addOrRemove = (lineId) => {
    const { expanded: exp } = this.state;
    const exists = exp.includes(lineId);
    const result = exists ? exp.filter(id => id !== lineId) : [...exp, lineId];
    this.setState({ expanded: result });
  }

  createRows = (list) => {
    const { expanded } = this.state;
    return (
      list.map((vendorOrder) => {
        const {
          id,
          poNumber,
          vendor,
          status,
          lineItems,
        } = vendorOrder;

        const actions = [<Link key={randInt()} to={`receiving-orders/${id}`}>Info</Link>];
        if (status !== 'processed') {
          actions.push(<span key={randInt()} className="mx-1">|</span>);
          actions.push(
            <Link key={randInt()} to={`receiving-shipments/new?vendor-order-id=${id}`}>
              New Shipment
            </Link>,
          );
        }

        const displayLineItems = lineItems.map(li => (
          <div key={li.id}>{li.quantity} - {li.name}</div>
        ));

        const liExpanded = expanded.includes(id);
        const expand = (
          <span
            className="link-text"
            onClick={() => this.addOrRemove(id)}
            aria-hidden="true"
          >
            {liExpanded ? 'Reduce' : 'Expand'}
          </span>
        );

        return (
          <tr key={randInt()}>
            <td>{id}</td>
            <td>{vendor}</td>
            <td>{formatVendorStatus(status)}</td>
            <td>{poNumber}</td>
            <td>{lineItems.length} | {expand}
              { expanded.includes(id) ? displayLineItems : null}
            </td>
            <td>{actions}</td>
          </tr>
        );
      })
    );
  }

  buildBadgeList = (filter, defaultFilter) => {
    const {
      itemId,
      search,
      searchReceivingShipments,
      status,
    } = filter;
    const {
      itemId: defaultItemId,
      search: defaultSearch,
      searchReceivingShipments: defaultsearchReceivingShipments,
      status: defaultStatus,
    } = defaultFilter;

    const badgeList = [];
    Object.keys(filter).forEach((key) => {
      switch (key) {
        case ('itemId'):
          if (itemId && itemId !== defaultItemId) {
            badgeList.push(
              {
                order: 4,
                title: 'For Item',
                descFilterable: 'items',
                filterKey: key,
              },
            );
          }
          break;
        case ('searchReceivingShipments'):
          if (searchReceivingShipments && searchReceivingShipments !== defaultsearchReceivingShipments) {
            badgeList.push(
              {
                order: 3,
                title: 'Search Receiving Shipments',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('search'):
          if (search && search !== defaultSearch) {
            badgeList.push(
              {
                order: 1,
                title: 'Search',
                desc: filter[key],
                filterKey: key,
              },
            );
          }
          break;
        case ('status'):
          if (status && status !== defaultStatus) {
            const desc = filter[key].join(', ').replace('unprocessed', 'not processed');
            badgeList.push(
              {
                order: 2,
                title: 'Status',
                desc,
                filterKey: key,
              },
            );
          }
          break;
        // no default
      }
    });

    return badgeList;
  }

  render() {
    const tableProps = {
      newButtonText: 'New Receiving Order',
      newButton: true,
      newFormUri: 'receiving-orders/new',
      pageTitle: 'Receiving Orders',
      searchPlaceholder: 'Search by ID, Vendor, PO #, or Invoice #...',
      heads: [
        { key: 'id', name: 'ID' },
        { key: 'vendor', name: 'Vendor' },
        { key: 'status', name: 'Status' },
        { key: 'poNumber', name: 'PO #' },
        { key: 'lineItems', name: 'Line Items' },
        { key: 'actions', name: 'Actions' },
      ],
      filterForm: [
        {
          type: 'CheckBoxGroup',
          groupLabel: 'Status',
          filterKey: 'status',
          options: [
            { value: 'processed', label: 'Processed' },
            { value: 'unprocessed', label: 'Not Processed' },
            { value: 'canceled', label: 'Canceled' },
          ],
        },
        {
          type: 'Text',
          groupLabel: 'Receiving Shipment Search',
          filterKey: 'searchReceivingShipments',
          placeholder: 'Tracking Number or ID...',
        },
        {
          type: 'Select',
          groupLabel: 'Item Type',
          filterKey: 'itemId',
          placeholder: 'Filter by Item Type...',
          optionsKey: 'items',
        },
      ],
      createRows: this.createRows,
      buildBadgeList: this.buildBadgeList,
    };

    return (
      <IndexTableContainer {...tableProps} />
    );
  }
}

export default withRouter(Index);
